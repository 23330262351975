import logo from '../assets/logo.png';
import './header.css';
import { useEffect, useState } from 'react';

export const Header = () => {
  useEffect(() => {
    let scrollPosition = 0;
    let lastScrollPosition = 0;
    document.addEventListener('scroll', () => {
      const header = document.getElementById('header');
      const sticky = 132;
      const scrolledToTop = scrollPosition - window.scrollY;
      if (window.scrollY - lastScrollPosition > 0) {
        scrollPosition = window.scrollY;
      }
      if (window.scrollY > sticky && scrolledToTop < sticky) {
        header.classList.add('invisible');
        setShowBurger(false);
      } else {
        header.classList.remove('invisible');
      }
      lastScrollPosition = window.scrollY;
    });
  }, []);

  const [showBurger, setShowBurger] = useState(false);

  return (
    <header id={'header'}>
      <div className={'header'}>
        <img src={logo} alt={'Logo'} className={'logo'} />
        <a href={'#stendart'} style={{ color: 'rgb(5, 85, 85)' }}>
          Stendart
        </a>
        {/* <a href={'/aviso-legal'}>AVISO LEGAL</a> */}
        <a href={'#descargar'}>Descargar</a>
        <a href={'#conocenos'}>Conócenos</a>
        <a href={'#artes'}>Artes</a>
        <a href={'#funciones'}>Funciones</a>
        <a href={'#faq'}>QA</a>
        <div
          onClick={() => setShowBurger((prev) => !prev)}
          className={`burger-menu ${
            showBurger && 'burger-menu-open'
          }`}>
          <div className={'burger-menu-item item-1 '}></div>
          <div className={'burger-menu-item item-2 '}></div>
          <div className={'burger-menu-item item-3 '}></div>
        </div>
      </div>
      {showBurger && (
        <div className={'absolute-container'}>
          <div className={'absolute-wrapper-items'}>
            <a href={'#stendart'}>Stendart</a>
            <div className='divide' />
            {/* <a href={'/aviso-legal'}>AVISO LEGAL</a>
            <div className='divide' /> */}
            <a href={'#descargar'}>Descargar</a>
            <div className='divide' />
            <a href={'#conocenos'}>Conócenos</a>
            <div className='divide' />
            <a href={'#artes'}>Artes</a>
            <div className='divide' />
            <a href={'#funciones'}>Funciones</a>
            <div className='divide' />
            <a href={'#faq'}>QA</a>
          </div>
        </div>
      )}
    </header>
  );
};
