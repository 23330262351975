import "./PrivacyPoilicy.css"
export const PrivacyPolicy = (props) => {

    return (
        <div className={"privacy"} id={"start"}>
            <h1>AVISO LEGAL</h1>
            <section>
                <p>En virtud de lo estipulado por el artículo 10 de la Ley 34/2002, de 11 de Julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico, se informa al usuario que el dominio stendarejemplo.com pertenece, como titular a:</p>
                <div className={"info"}>
                    <i>STENDART LLC (en adelante, STENDART)</i>
                    <i>EIN:  98-1729664</i>
                    <i>16192 Coastal Highway, 19958</i>
                    <i>Lewes (Delaware)</i>
                    <i>stendart.app</i>
                </div>
                <p>La oficina registral de la compañía en el Estado de Delaware está emplazada en el 16192 Coastal Highway, Lewes, Sussex County, Delaware, 19958-9776.</p>
            </section>
            <section>
                <h2>CONDICIONES DE USO</h2>
                <p>El uso de esta website se regirá por las presentes condiciones, incluyéndose los contenidos y servicios puestos a disposición de los usuarios. Es por ello que desde STENDART le rogamos que lea atentamente estas condiciones ya que contienen toda la información relativa a sus derechos y obligaciones.</p>
                <p>Debe saber que con el mero acceso a la website ya se le considerará como “usuario” de la misma y con ello acepta expresamente, de forma automática y voluntaria, las condiciones vigentes en cada momento. En caso de que no desee aceptarlas se le recomienda no seguir haciendo uso de la website. Destacar que el acceso a stendarejemplo.com es gratuito y no exige previa suscripción o registro.</p>
                <p>STENDART se reserva el derecho, en exclusiva y en cualquier momento, a revisar las presentes condiciones para introducir modificaciones derivadas de cambios legislativos o por decisiones corporativas, comprometiéndose a publicar y con ello informar de las variaciones que pudieran afectar a los usuarios. Estas modificaciones se entenderán aceptadas cuando el usuario siga haciendo uso de la website.</p>
                <p>Asimismo, STENDART se reserva el derecho a modificar unilateralmente, en cualquier momento y sin previo aviso, la estructura y diseño de la website, así como los servicios o contenidos de la misma. Con estos mismos términos, durante las tareas de modificación, reparación, actualización o mejoras se podrá suspender el acceso a la website.</p>
                <p>Podría ocurrir que derivado de las modificaciones en la website descritas en el párrafo anterior se incluyeran nuevos contenidos o servicios que además de estar sujetos a estas Condiciones de Uso también lo estuvieran a Condiciones de Uso Particulares o Especiales que ampliaran o sustituyeran las presentes. En caso de que hubiera alguna discordancia entre las Condiciones de Uso Generales y las Condiciones de Uso Particulares, prevalecerá lo estipulado en estas últimas.</p>
                <ul>
                    <li>Un derecho revocable y no exclusivo.</li>
                    <li>En ningún caso podrá el usuario dar a entender que STENDART recomienda dicho sitio web, servicios o productos, ni falsear su relación.</li>
                    <li>No debe incluir contenidos considerados de mal gusto, obscenos, ofensivos controvertidos, incitadores a la violencia o discriminación por sexo, raza o religión, contrarios al orden público o ilícitos.</li>
                    <li>El enlace debe dirigir a la website, sin que se permita la reproducción como parte de su web o dentro de uno de sus frames o crear un browser sobre cualquiera de las páginas de la website.</li>
                </ul>
            </section>
            <section>
                <p>A los exclusivos efectos de este contrato se entenderá por:</p>
                <div className={"fake-list"}>
                    <p><b>1.1 - APLICACIÓN INFORMÁTICA / PLATAFORMA INFORMÁTICA</b>: Plataforma informática que conecta a artistas de diferentes disciplinas. Se trata de un espacio en línea en el que se promueve la interacción y colaboración de artistas, lo que permite crecer en su carrera y explorar nuevas oportunidades, y se denomina comercialmente “STENDART”.</p>
                    <p><b>1.2 - LICENCIANTE: STENDART LLC</b>, titular de todos los derechos de propiedad intelectual existentes sobre la aplicación y de los derechos de propiedad industrial sobre los signos distintivos que la identifican en el mercado.</p>
                    <p><b>1.3 - LICENCIATARIA</b>: persona física o jurídica beneficiaria de la licencia de uso y/o habilitada para el uso de la aplicación, la cual asume las obligaciones del presente contrato.</p>
                    <p><b>1.4 - PARTES</b>: LICENCIANTE y LICENCIATARIA.</p>
                    <p><b>1.5 - TITULAR DE LA APLICACIÓN</b>: la sociedad STENDART LLC.</p>
                    <p><b>1.6 - TERCERO</b>: persona física o jurídica que no forma parte de esta relación contractual.</p>
                </div>
            </section>
            <section className={"buttons"}>
                <a href={"#start"}><button>Volver arriba</button></a>
                <a href={"/"}><button>Inicio</button></a>
            </section>
        </div>
    )
}
