import './App.css';
import {Header} from "./components/Header";
import banner from "./assets/banner1.png"
import playStore from "./assets/playStore.png"
import appStore from "./assets/appStore.png";
import stendartTitle from "./assets/stendart.svg"
import banner2 from "./assets/banner2.png";
import logoRoles from "./assets/logoRoles.png";
import banner3 from "./assets/banner3.jpg"
import {Footer} from "./components/Footer";

function App() {
    return (
        <div className="App">
            <Header />
            <div className={"description"}>
                <div className={"description-back"} />
                <div className={"description-wrapper"} id={"faq"}>
                    <div className={"text"}>
                        <div>
                        <img src={stendartTitle} alt={"Title"} />
                        <p>¡Bienvenido a Stendart, la revolucionaria aplicación móvil que cambia las reglas del juego para los artistas de todo el mundo!</p>
                            <br />
                        <p>Si te dedicas a la fotografía, diseño gráfico, maquillaje, peluquería, tatuaje, modelaje o  pintura; esta es la plataforma que estabas esperando.</p>
                            <br />
                        <p>Imagina un espacio en el que artistas de todos los niveles, ya sean amateurs o profesionales, puedan conectarse, colaborar y crear proyectos juntos. En Stendart se hace realidad.</p>
                            <br />
                        <p>Nuestra App ofrece una forma fácil y emocionante de encontrar artistas afines y dar vida a tus trabajos y proyectos.</p>
                        </div>
                    </div>
                    <img src={banner} alt={"Logo"} className={"banner"} />
                </div>
            </div>
            <div className={"main"}>
                <div className={"store"} id={"descargar"}>
                    <h2>DESCARGA LA APP</h2>
                    <p>Disponible para Android e iOs en versión BETA.</p>
                    <div className={"store-wrapper"}>
                        <a href={"https://play.google.com/store/apps/details?id=com.stendart"}>
                            <img src={playStore} alt={"Play store"} style={{marginRight: "18px"}} />
                        </a>
                        <a href={"https://apps.apple.com/us/app/stendart-por-y-para-artistas/id1605965415"}>
                            <img src={appStore} alt={"App store"} />
                        </a>
                    </div>
                </div>
                <div className={"example"} id={"conocenos"}>
                    <img src={banner2} alt={"Banner 2"} />
                    <div className={"example-wrapper"}>
                        <p>Stendart te da la libertad de decidir cómo quieres trabajar con el resto de artistas y te brinda la oportunidad de hacer realidad tus sueños creativos.</p>
                        <br />
                        <p>Aquí no se trata solo de conexiones. También te ofrece una comunidad vibrante y apasionada de artistas que comparten sus pasiones y proyectos.</p>
                        <br />
                        <p>Comparte tus experiencias, busca inspiración y aprende de otros expertos en el campo.</p>
                        <br/>
                        <p>Juntos, construiremos una red global de creatividad que trasciende fronteras y culturas. Esta es la misión de Stendart.</p>
                    </div>
                </div>
                <img id={"artes"} src={logoRoles} alt={"Roles"} className={"roles"} />
                <div className={"features"} id={"funciones"}>
                    <h2>TODA UNA COMUNIDAD DE</h2>
                    <h2>ARTISTAS AL ALCANCE DE TU MANO</h2>
                    <div className={"features-wrapper"}>
                        <ul className={"features-list"}>
                            <li>¡Publica tus mejores trabajos, crea proyectos y conoce nuevos artistas!</li>
                            <li>Usa tu perfil como portfolio y muestra tu talento al mundo.</li>
                            <li>Trabaja en proyectos remunerados o colaborativos. Donde podrás obtener calificaciones por tu trabajo.</li>
                            <li>Apoya a los artistas. Stendart cuenta con un sistema de donaciones para que el resto de artistas premien tu trabajo.</li>
                            <li>Esto es solo el comienzo. Stendart tendrá muchas sorpresas para ti muy pronto...</li>
                        </ul>
                        <img src={banner3} alt={"Banner 3"} />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default App;
