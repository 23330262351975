import "./styles.css"

export const Kickstarter = () => {

    return (
        <div className={"main"}>
            <div className={"content"}>
                <a href={"https://www.kickstarter.com/projects/stendart/stendart-the-app-for-artists"}>
                    <img src={require("../../assets/kickstarter/supportKickstarter.png")} style={{marginTop: "40px"}} alt={""} />
                </a>
                <img src={require("../../assets/kickstarter/title1.png")} alt={""} />
                <p>¡Tras más de tres años en desarrollo, llega la versión Beta de <b>STENDART</b>, la revolucionaria aplicación móvil que cambia las reglas del juego para los artistas! Si estás en el mundo del <b>maquillaje, peluquería, moda, tatuaje, modelaje, fotografía, diseño gráfico</b> o <b>pintura</b>... ¡Esta es la plataforma que estabas esperando!</p>
                <div style={{height: "24px"}} />
                <img src={require("../../assets/kickstarter/kickLogo.png")} alt={""} />
                <p>Hemos desarrollado un espacio en el que artistas de todos los niveles, ya sean <b>amateurs</b> o <b>profesionales</b>,  puedan conectarse,  colaborar y crear proyectos juntos.</p>
                <p>¡Pero no todo acaba aquí! <b>Si no eres artista</b> y estás buscando uno al que contratar, <b>¡ésta también es tu App!</b> Stendart te ofrece la posibilidad de registrarte como usuario CASUAL. Por lo que, al igual que si eres artista, podrás encontrar todo tipo de profesionales con los que contactar para un servicio o proyecto.</p>
                <p><b>Nuestra App ofrece una forma fácil y emocionante de encontrar artistas afines y dar vida a tus trabajos e ideas.</b></p>
                <div className={"spacer"} />
                <img src={require("../../assets/kickstarter/title2.png")} alt={""} />
                <img src={require("../../assets/kickstarter/appFunctions.png")} alt={""} />
                <div className={"fullSpacer"} />
                <img src={require("../../assets/kickstarter/stendartGif.gif")} alt={""} />
                <img src={require("../../assets/kickstarter/divider.png")} alt={""} />
                <img src={require("../../assets/kickstarter/title3.png")} alt={""} />
                <p>Nuestro equipo está entusiasmado por llevar a Stendart al <b>siguiente nivel</b>, y <b>ahí es donde necesitamos tu apoyo</b>.  Con tu contribución, podremos mejorar la funcionalidad de la aplicación, agregar nuevas características y lanzar campañas de marketing para que más artistas puedan unirse a esta <b>comunidad en crecimiento.</b></p>
                <p>Únete a nosotros en esta emocionante aventura creativa. <b>Apoya nuestra campaña y sé parte del
                    movimiento Stendart.</b></p>
                <div className={"spacer"} />
                <img src={require("../../assets/kickstarter/iphoneFrame.png")} alt={""} />
                <p><b>Pero no podemos hacerlo solos.</b> Necesitamos tu apoyo para hacer realidad este propósito. Tu donación tendrá un <b>impacto significativo</b> al posibilitarnos implementar las mejoras y optimizaciones del proyecto, brindando a los artistas la <b>visibilidad</b> y el <b>reconocimiento</b> que verdaderamente merecen.</p>
                <div className={"spacer"} />
                <img src={require("../../assets/kickstarter/timeline.jpg")} alt={""} />
                <div className={"fullSpacer"} />
                <img src={require("../../assets/kickstarter/someTable.jpg")} alt={""} />
                <div className={"fullSpacer"} />
                <img src={require("../../assets/kickstarter/divider.png")} alt={""} />
                <img src={require("../../assets/kickstarter/warning.png")} alt={""} />
                <a className={"kstore"} href={"https://apps.apple.com/us/app/stendart-por-y-para-artistas/id1605965415"}>
                    <img src={require("../../assets/kickstarter/appStoreButton.png")} alt={""} style={{marginBottom: "8px"}}/>
                </a>
                <a className={"kstore"} href={"https://play.google.com/store/apps/details?id=com.stendart"}>
                    <img src={require("../../assets/kickstarter/googlePlayButton.png")} alt={""} />
                </a>
                <div className={"fullSpacer"} />
                <img src={require("../../assets/kickstarter/divider.png")} alt={""} />
                <div>
                    <h3>Riesgos y desafíos</h3>
                    <p>Cómo en todo proyecto, existen ciertos riesgos a tener en cuenta. Nosotros los diferenciamos entre internos y externos siendo los primeros responsabilidad de nuestro equipo y los últimos tratándose de factores ajenos a éste.</p>
                    <h4>Factores internos:</h4>
                    <p>- Limitaciones de escalabilidad. Refiriéndonos a la capacidad de adaptación y respuesta de nuestra aplicación respecto a su rendimiento, a medida que aumentan de forma significativa el número de usuarios en ésta. Contamos con una buena respuesta actualmente dentro de la aplicación, pero existe el riesgo de que cuando empiece a aumentar el número de usuarios, dejemos de tener ese buen funcionamiento. Como solución a esto, siempre estaremos dispuestos a contratar más equipo y mejorar los sistemas. Con esto se podría mejorar el rendimiento, reducir la carga en recursos y reducir la latencia entre otros problemas que pudieran producirse.</p>
                    <p>- Renovación. Otro de los desafíos a tener en cuenta es el hecho de no quedarse estancados. Nuestro proyecto trata de una aplicación, es algo que está a la orden del día y de las nuevas tecnologías por lo que nuestro equipo deberá mantener activos procesos de renovación y actualizaciones.</p>
                    <h4>Factores externos:</h4>
                    <p>- Aceptación de la App. La principal incertidumbre que enfrentamos con este proyecto es la respuesta que obtengamos por parte del público y los usuarios. Para encarar este desafío, nuestro equipo está enfocado en desarrollar estrategias de marketing y publicidad que resalten la visibilidad de nuestra aplicación y sus beneficios. De este factor depende el éxito del proyecto en última instancia.</p>
                    <p>- Posibles competidores. Aunque en la actualidad no hay ninguna plataforma o red social que se asemeje a Stendart, siempre existe la posibilidad de que surjan imitaciones de competidores. Nuestro equipo está atento a la posibilidad de que surjan rivales con recursos económicos más amplios que los nuestros, pero que carezcan de nuestros mismos valores éticos. Nuestra principal meta radica en aumentar la visibilidad del trabajo de los artistas y en establecer un espacio que los conecte con oportunidades laborales, lo que representa nuestra característica distintiva. Además, nuestro proyecto gozaría de ventajas frente a los competidores gracias al tiempo y los recursos significativos que hemos invertido en su desarrollo.</p>
                    <hr />
                </div>
                <div style={{width: "100%"}}>
                    <h3>Recompensas</h3>
                    <div className={"contribute"}>
                        <div className={"rank"}>
                            <p className={"underline"}>Contribuye 15 $ o más</p>
                            <img src={require("../../assets/kickstarter/bronzeSupporter.png")} alt={""} />
                        </div>
                        <span>Este es el primer nivel de patrocinio, con un mínimo de inversión nos estás ayudando a crecer y a mejorar. A cambio nuestro equipo te obsequiará con lo siguiente:</span>
                        <span className={"penis"}>*Estas recompensas solo se podrán aplicar a cuentas de artistas, no serán efectivas en cuentas "casual".</span>
                        <ul>
                            <li>⭐ TRES meses de cuenta PREMIUM</li>
                        </ul>
                        <section> <hr /></section>
                    </div>
                    <div className={"contribute"}>
                        <div className={"rank"}>
                            <p className={"underline"}>Contribuye 50 $ o más</p>
                            <img src={require("../../assets/kickstarter/silverSupporter.png")} alt={""} />
                        </div>
                        <span>Esto se pone serio, si perteneces a este nivel es porque te ha gustado nuestro trabajo y confías en nuestro equipo. Como agradecimiento te obsequiaremos con lo siguiente:</span>
                        <span className={"penis"}>*Estas recompensas solo se podrán aplicar a cuentas de artistas, no serán efectivas en cuentas "casual".</span>
                        <ul>
                            <li>⭐ SEIS meses de cuenta PREMIUM</li>
                            <li>🛒 Un listings extra (4) para la tienda</li>
                            <li>👑 Insignia VISIONARIO/A en tu perfil de STENDART</li>
                        </ul>
                        <section> <hr /></section>
                    </div>
                    <div className={"contribute"}>
                        <div className={"rank"}>
                            <p className={"underline"}>Contribuye 200 $ o más</p>
                            <img src={require("../../assets/kickstarter/goldenSupporter.png")} alt={""} />
                        </div>
                        <span>¡Ya perteneces a Stendart! Gracias a tu contribución podremos realizar grandes mejoras e innovaciones en nuestra app. Como agradecimiento por ello, nuestro equipo te obsequiará con lo siguiente:</span>
                        <span className={"penis"}>*Estas recompensas solo se podrán aplicar a cuentas de artistas, no serán efectivas en cuentas "casual".</span>
                        <ul>
                            <li>⭐ DOS años de cuenta PREMIUM</li>
                            <li>🛒 CUATRO listings extra (7) para la tienda</li>
                            <li>💲 Retención de un 15% en transacciones durante un año (2̶0̶%)</li>
                            <li>👑 Insignia EMBAJADOR/A en tu perfil de STENDART</li>
                        </ul>
                        <section> <hr /></section>
                    </div>
                    <h3 style={{textAlign: "center"}}>¡Atención! Las contribuciones se deberán hacer en el post original de <br /><a href={"/"}>KICKSTARTER</a></h3>
                </div>
            </div>
        </div>
    )
}
