import logo from '../assets/logoBW.png';
import './footer.css';

export const Footer = () => {
  return (
    <footer id={'faq'}>
      <img src={logo} alt={'Logo b/w'} className={'footer-logo'} />
      <a className={'mailLink'} href={'mailto:support@stendart.app'}>
        support@stendart.app
      </a>
      <div className={'containerLinks'}>
        <a href='/privacy-policy'>AVISO LEGAL </a>
        <a href='/privacy-policy'>- POLÍTICA DE PRIVACIDAD -</a>
        <a href='/privacy-policy'> COOKIES</a>
      </div>
      <br />
      <div className={'divide'} />
      <br />
      {/* <p>AVISO LEGAL - POLÍTICA DE PRIVACIDAD - COOKIES</p> */}
      <p>COPYRIGT STENDART 2023</p>
      <p className={'company'}>STENDART ES UNA APP DE STENDART LLC</p>
    </footer>
  );
};
